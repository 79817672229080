import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/style/main.scss";
import "./assets/style/_variables.scss";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";

// components
import BaseSelect from "@/components/base/Select";
import BaseCombobox from "@/components/base/Combobox";
import BaseAutocomplete from "@/components/base/Autocomplete";
import BaseInput from "@/components/base/Input";
import BaseDate from "@/components/base/Date";
import BasePassword from "@/components/base/Password";
import BaseTextarea from "@/components/base/Textarea";
import UploadImage from "@/components/base/UploadImage";
import DeleteDialog from "@/components/dialogs/Delete";
import i18n from "./i18n";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import axios from "./plugins/axios";
import VueMeta from 'vue-meta'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import * as VueGoogleMaps from 'vue2-google-maps';


Vue.config.productionTip = false;
Vue.prototype.$showImage = (id) => {
  // eslint-disable-next-line no-useless-escape
  if (id?.name) {
    return id
  } else {

    // check if id contain empty char
    let isEmptyChar = id?.includes(' ') ? true : false

    let replacedID = isEmptyChar ? id ? id.replace(/ /g, '%20') : '' : id
    return id ? `https://api.martofworld.com/${replacedID}` : 'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png';
    // return id ? `https://martofworld.onrender.com/${replacedID}` : 'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png';
  }
};
Vue.prototype.$defaultAvatar = () => {
  return 'https://api-private.atlassian.com/users/9cea692d0a59c5e100680165cbbeb496/avatar';
};

Vue.prototype.$defaultRequestImage = (typeID) => {
  if (typeID == 1) return require('@/assets/images/website/dummy/buy_offer.svg')
  if (typeID == 2) return require('@/assets/images/website/dummy/sale_offer.svg')
  if (typeID == 3) return require('@/assets/images/website/dummy/cooperation_offer.svg')
  if (typeID == 4) return require('@/assets/images/website/dummy/transportation_offer.svg')
  if (typeID == 5) return require('@/assets/images/website/dummy/tender_offer.svg')
};
Vue.prototype.$currentTabLang = (tab) => {
  if (tab == 1) return 'ar'
  if (tab == 2) return 'de'
  if (tab == 3) return 'es'
  if (tab == 4) return 'fr'
  if (tab == 5) return 'tr'
  if (tab == 6) return 'ru'
};

Vue.component("base-select", BaseSelect);
Vue.component("base-combobox", BaseCombobox);
Vue.component("base-input", BaseInput);
Vue.component("base-date", BaseDate);
Vue.component("base-password", BasePassword);
Vue.component("base-textarea", BaseTextarea);
Vue.component("base-autocomplete", BaseAutocomplete);
Vue.component("upload-image", UploadImage);
Vue.component("delete-dialog", DeleteDialog);
Vue.component("slick-slider", VueSlickCarousel);

Vue.use(axios);

const globalOptions = {
  mode: 'international',
  
};
Vue.use(VueTelInput, globalOptions);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDwdW1S-SQ8eQWJ6thgJK5TRmCN3GYjYWo',
  }
})

Vue.mixin({
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isMd() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md
      );
    },
    isLg() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg
      );
    },
  },
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// change picker package
